
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { locale } = useI18n();
    const store = useStore();
    const router = useRouter();

    document.title = translate("SIGNUP_PAGE_TITLE")

    const submitButton = ref<HTMLElement | null>(null);
    const buttonLabel = ref<string>(translate("TRY_AGAIN_SUBMIT_BUTTON"));
    const registerProcessLabel = ref<string>(translate("REGISTRATION_PROCESS"));
    const agree = ref<boolean>(false);
    const successMessageContent = ref<string>(
      translate("FEEDBACK_VERIFICATION_MAIL_SENDING_SUCCESSFUL")
    );
    const errorMessageContent = ref<string>(
      translate("FEEDBACK_ACCOUNT_CREATION_FAILED")
    );
    const registerNowLabel = ref<string>(translate("REGISTER_NOW"));
    const registerCancelLabel = ref<string>(
      translate("REGISTER_CANCEL_BUTTON")
    );

    onMounted(() => {
      store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);
    });

    const selectLists = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const registration = reactive(
      computed(() =>
        Yup.object().shape({
          p_iva: Yup.string()
            .required(translate("VAT_NUMBER_REQUIRED"))
            .length(11, translate("REGISTRATION_P_IVA_INVALID")),
          denom: Yup.string()
            .required(translate("LEGAL_ENTITY_REQUIRED"))
            .max(128, translate("REGISTRATION_DENOM_INVALID"))
            .matches(
              /^[a-zA-Z .0-9]{1,128}$/,
              translate("REGISTRATION_DENOM_INVALID")
            ),
          settore: Yup.string().required(
            translate("INDUSTRIAL_SECTOR_REQUIRED")
          ),
          fatturati: Yup.string().required(translate("REVENUE_RANGE_REQUIRED")),
          employee_range: Yup.string().required(
            translate("EMPLOYEE_RANGE_REQUIRED")
          ),
          user_name: Yup.string()
            .matches(
              /^[a-zA-Z0-9]{2,64}$/,
              translate("USERNAME_CHARACTER_VALIDATION")
            )
            .required(translate("USERNAME_REQUIRED")),
          email: Yup.string()
            .required(translate("EMAIL_REQUIRED"))
            .email(translate("VALID_EMAIL")),
          password: Yup.string()
            .min(6, translate("REGISTRATION_PASSWORD_PLACEHOLDER"))
            .required(translate("PASSWORD_REQUIRED")),
          email_confirmation: Yup.string()
            .required(translate("EMAIL_CONFIRMATION_REQUIRED"))
            .oneOf([Yup.ref("email"), null], translate("EMAIL_MUST_MATCH")),
          password_confirmation: Yup.string()
            .required(translate("PASSWORD_CONFIRMATION_REQUIRED"))
            .oneOf(
              [Yup.ref("password"), null],
              translate("PASSWORD_MUST_MATCH")
            ),
          role: Yup.string().matches(
            /^[a-z A-Z]{0,64}$/,
            translate("REGISTRATION_ROLE_INVALID")
          ),
          // tel: Yup.string().matches(
          //   /^[0-9]$/,
          //   translate("REGISTRATION_PHONE_PLACEHOLDER")
          // ),
          currentIsAssociated: Yup.string().required(
            translate("MEMBERSHIP_REQUIRED")
          ),
          currentIsQuoted: Yup.string().required(translate("LISTING_REQUIRED")),
          company_type: Yup.string().required(
            translate("COMPANY_TYPE_REQUIRED")
          ),
          agree: Yup.string().required(translate("AGREE_REQUIRED")),
        })
      )
    );

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      Swal.fire({
        title: registerProcessLabel.value,
        type: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-dark-blue",
          cancelButton: "btn fw-bold btn-outline",
        },
        confirmButtonText: registerNowLabel.value,
        cancelButtonText: registerCancelLabel.value,
        closeOnConfirm: false,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send register request
          store
            .dispatch(Actions.REGISTER, values)
            .then(() => {
              Swal.fire({
                text: successMessageContent.value,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                router.push({ name: "sign-in" });
              });
            })
            .catch(() => {
              Swal.fire({
                text: errorMessageContent.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: buttonLabel.value,
                customClass: {
                  confirmButton: "btn fw-bold btn-dark-blue",
                },
              });
            })
            .finally(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        }
      });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      translate,
      translateKey,
      selectLists,
      agree,
      locale,
    };
  },
});
